.loader {
    -webkit-animation: spinner 1.5s linear infinite;
    animation: spinner 1.5s linear infinite;
}

.apexcharts-toolbar {
    z-index: 0 !important;
}

@-webkit-keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

ul li {
    list-style-type: disc;
}

ol li {
    list-style-type: decimal;
}

body,
html {
    @apply font-body;
    margin: 0 !important;
    padding: 0 !important;
}

::-webkit-scrollbar {
    width: 0px;
    height: 0px;
}

button:focus {
    outline: none;
}

.slidecontainer {
    width: 100%; /* Width of the outside container */
}

#staking-modal {
    opacity: 1 !important;
}

// Staking Pool Columns
.sp-col-1 {
    width: 100%;
    @media screen and (min-width: 1024px) {
        width: 32%;
    }
}

.sp-col-2 {
    width: 12%;
}
.sp-col-3 {
    width: 12%;
}
.sp-col-4 {
    width: 12%;
}

.sp-col-5 {
    width: 20%;
}

.sp-col-6 {
    width: 12%;
}

/* Customize the label (the container) */
.checkbox-container {
    display: block;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #110622;
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkmark {
    background-color: #110622;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
    background-color: #ae1e55;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: '';
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
    left: 6px;
    top: 0px;
    width: 8px;
    height: 16px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.dark-placeholder::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #41384e;
    opacity: 1; /* Firefox */
}

.dark-placeholder:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #41384e;
}

.dark-placeholder::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #41384e;
}
.dark-placeholder::-webkit-input-placeholder {
    color: #41384e;
}

.dark-placeholder::-moz-placeholder {
    color: #41384e;
}
.dark-placeholder:-ms-input-placeholder {
    color: #41384e;
}
.dark-placeholder:placeholder {
    color: #41384e;
}

option[value=''][disabled] {
    display: none;
}
option {
    color: #ffffff !important;
}

.onfido-sdk-ui-crossDevice-CrossDeviceLink-qrCodeContainer {
    height: 9em;
    margin-bottom: 1em;
    display: inline-block;
}

strong {
    font-weight: 1000;
}

.hexagon {
    position: relative;
    width: 450px;
    height: 259.81px;
    background-color: #ae1e55;
    margin: 129.9px 0;
}

.hexagon:before,
.hexagon:after {
    content: '';
    position: absolute;
    width: 0;
    border-left: 225px solid transparent;
    border-right: 225px solid transparent;
}

.hexagon:before {
    bottom: 100%;
    border-bottom: 129.9px solid #ae1e55;
}

.hexagon:after {
    top: 100%;
    width: 0;
    border-top: 129.9px solid #ae1e55;
}

.bounty-gradient-card {
    background: linear-gradient(294.68deg, #ae1e55 -71.35%, #241b31 42.43%);
    border: 1px solid rgba(255, 255, 255, 0.03);
}

.error-gradient {
    background: linear-gradient(79.26deg, #ae1e55 -76.65%, #241b31 52.1%);
    border: 1px solid rgba(255, 255, 255, 0.03);
}

:root {
    --onboard-modal-z-index: 50;
}

.grecaptcha-badge {
    display: none !important;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
    input[type='range']::-webkit-slider-thumb {
        width: 0.75rem;
        -webkit-appearance: none;
        appearance: none;
        height: 0.75rem;
        cursor: ew-resize;
        background: #fff;
        box-shadow: -405px 0 0 400px #ae1e55;
        border-radius: 100%;
    }
}
